import React from 'react';
import MainLayout from '../components/MainLayout';
import AdminMenu from '../components/AdminMenu';

//e@e.e
const AdminPanel = () => {
	return (
		<MainLayout>
			<div>
				<AdminMenu></AdminMenu>		
			</div>
		</MainLayout>
	)
}

export default AdminPanel
